import React, { FC, useState } from 'react'
import 'twin.macro'
import { Transition } from '@tailwindui/react'

import { Layout } from './Layout'
import { DesktopNavigation, MobileNavigation } from './Navigation'

export const PlainTemplate: FC = ({ children }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const toggleMobileNav = () => setMobileNavOpen(!mobileNavOpen)

  return (
    <Layout>
      <div tw="relative bg-white">
        {/* <div tw="max-w-screen-xl mx-auto"> */}
          {/* <div tw="relative z-10 pb-6 bg-white"> */}
            {/* <div tw="relative pt-6 px-4 sm:pt-12 sm:px-6 lg:px-8"> */}
            <div tw="py-4">
              <DesktopNavigation onToggleMobile={toggleMobileNav} light />
            </div>

            <Transition
              show={mobileNavOpen}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {(ref) => (
                <div ref={ref} tw="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                  <MobileNavigation onToggleMobile={toggleMobileNav} />
                </div>
              )}
            </Transition>

          {/* </div>
        </div> */}
      </div>

      {children}
    </Layout>
  )
}
